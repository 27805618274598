<template>
  <b-container
    fluid
    class="mb-5"
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Filter By Dealer</label>
            <b-select
              v-model="filter.dealer"
              :options="filterDealer"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table
            ref="table"
            hover
            responsive
            class="mt-2"
            :per-page="tableSettings.perPage"
            :current-page="tableSettings.currentPage"
            :items="myProvider"
            :fields="tableSettings.fields"
            :sort-by.sync="tableSettings.sortBy"
            :sort-desc.sync="tableSettings.sortDesc"
            :sort-direction="tableSettings.sortDirection"
            :filter="tableSettings.filter"
            :filter-included-fields="tableSettings.filterOn"
            show-empty
          >
            <template #cell(index)="data">
              {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index +
                1)
              }}
            </template>

            <template #cell(active)="data">
              {{ data.item.active === 1 ? "Yes" : 'No' }}
            </template>

            <template #cell(actions)="row">
              <div class="text-nowrap">
                <b-button
                  style="margin-right: 2px;"
                  size="sm"
                  @click="onShowProductForm(row.item)"
                >
                  Add Products
                </b-button>

                <b-button
                  size="sm"
                  @click="onShowProductDetails(row.item)"
                >
                  Show Products
                </b-button>
              </div>
            </template>
          </b-table>
          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                <b-select
                  v-model="tableSettings.perPage"
                  :options="tableSettings.pageOptions"
                  size="sm"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="tableSettings.currentPage"
                :total-rows="tableSettings.totalRows"
                :per-page="tableSettings.perPage"
                first-number
                last-number
                pills
                prev-text="Prev"
                next-text="Next"
                aria-controls="table"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-product-form"
      scrollable
      size="xl"
      no-close-on-backdrop
      title="Products"
      class="mb-5"
      @ok="onConfirmSubmit"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <b-row>
            <b-col
              cols="12"
              sm="4"
            >
              <div class="form-group">
                <label>Product Category</label>
                <v-select
                  id="product_details"
                  v-model="product.product_category_id"
                  :options="product_categories"
                  placeholder="Select Category"
                />
              </div>
            </b-col>

            <b-col
              cols="12"
              sm="4"
            >
              <div class="form-group">
                <label>Product</label>
                <v-select
                  v-model="product.product_id"
                  :options="products"
                  placeholder="Select Product"
                />
              </div>
            </b-col>

            <b-col
              cols="12"
              sm="4"
            >
              <ValidationProvider
                #default="{ errors }"
                name="quantity"
                vid="quantity"
                rules="numeric|required"
              >
                <div class="form-group">
                  <label for="quantity">Quantity</label>
                  <b-input
                    id="quantity"
                    v-model="product.quantity"
                    type="number"
                    :class="`${errors.length > 0 ? ' is-invalid' : ''}form-control-sm `"
                    placeholder="enter quantity"
                    autocomplete="off"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </div>

              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col cols="12">
              <div />
              <b-button
                type="button"
                variant="success"
                @click="addItem"
              >
                Add Product
              </b-button>
            </b-col>
          </b-row>

          <div>
            <b-table
              striped
              hover
              :items="items"
              :fields="tableProductDetails.fields"
            >
              <template #cell(action)="row">
                <b-button
                  size="sm"
                  @click="removeSaleorderItem(row.item)"
                >
                  remove
                </b-button>
              </template>
            </b-table>

          </div>

        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          :disabled="items.length == 0"
          variant="success"
          @click="ok()"
        >
          {{ editMode ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>

    </b-modal>
    <b-modal
      id="modal-product-show"
      scrollable
      size="xl"
      no-close-on-backdrop
      title="Products List"
      class="mb-5"
    >

      <div>
        <b-table
          ref="table"
          striped
          hover
          :items="productlist_dealer"
          :fields="tableShowProductDetails.fields"
        >

          <template #cell(quantity)="data">
            {{ data.item.quantity }}
          </template>
          <template #cell(action)="data">
            <div class="text-nowrap">
              <b-button
                size="sm"
                @click="showEditProduct(data.item)"
              >
                Edit
              </b-button>
            </div>
          </template>

        </b-table>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close
        </b-button>
      </template>

    </b-modal>

    <b-modal
      id="modal-edit-product"
      scrollable
      no-close-on-backdrop
      title="Edit Product Quantity"
      @ok="handleEdit"
    >

      <validationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <ValidationProvider
            #default="{ errors }"
            name="product_name"
            vid="product_name"
          >
            <b-form-group>
              <label for="product_name">Product Name</label>
              <b-input
                id="product_name"
                v-model="product.product_name"
                type="text"
                :disabled="true"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="quantity"
            vid="quantity"
          >
            <b-form-group>
              <label for="quantity">Quantity</label>
              <b-input
                id="quantity"
                v-model="product.quantity"
                type="number"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

        </form>

      </validationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          @click="ok()"
        >
          Update Quantity
        </b-button>
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AxiosError, SharedUserService, ListService, StockService } from '@/services'
import formatter from '@/mixins/formatter'
// import Input from '@/components/FormInput/InputType'
// import InputSelect from '@/components/FormInput/InputSelect'

export default {
  name: 'UserInventoryBeginning',

  middleware: ['auth', 'user'],

  mixins: [formatter],

  data () {
    return {
      isBusy: true,
      editMode: false,
      stock_details: {
        dealer_id: '',
        inventory_type: ''

      },
      dealers: [],
      filter: {
        dealer: 'All'
      },
      product: {
        product_category_id: '',
        product_id: '',
        quantity: 0,
        product_name: '',
        stock_id: '',
        dealer_id: '',
        old_quantity: ''
      },
      product_categories: [],
      products: [],
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'area_code', sortable: true },
          { key: 'area_name', sortable: true },
          { key: 'dealer_code', sortable: true },
          { key: 'dealer_name', sortable: true },
          { key: 'address', sortable: true },
          { key: 'active', label: 'IS ACTIVE?' }
        ]
      },
      tableProductDetails: {
        fields: [
          {
            key: 'product_category_name',
            sortable: true
          },
          {
            key: 'product_name',
            sortable: true
          },
          {
            key: 'quantity',
            sortable: true
          },
          'action'
        ]
      },
      tableShowProductDetails: {
        fields: [
          {
            key: 'product_category_name',
            sortable: true
          },
          {
            key: 'product_name',
            sortable: true
          },
          'quantity',
          'action'
        ]

      },
      items: [],
      productlist_dealer: [],
      user: this.$store.getters['auth/user'],
      is_update: null
    }
  },

  computed: {
    filterDealer () {
      return [{ value: 'All', text: 'All' }].concat(this.dealers)
    }
  },
  watch: {
    'product.product_category_id.value' (value) {
      this.getProduct(value)
    },
    'filter.dealer' () {
      this.$refs.table.refresh()
    }

  },

  mounted () {
    core.index()
    this.getProductCategory()
    this.getDealer()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await SharedUserService.getDealerByDcp(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}&filter_dealer=${this.filter.dealer}`)
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getIsUpdate () {
      await SharedUserService.getIsUpdate().then(({ data }) => {
        this.is_update = data.items[0].is_update
      })
    },

    async getDealer () {
      await ListService.getDealers().then(({ data }) => {
        this.dealers = data.map(({ id, dealer_name }) => {
          return { value: id, text: dealer_name }
        })
      })
    },

    async getProductCategory () {
      this.product_categories = []
      await ListService.getProductCategories().then(({ data }) => {
        data.forEach(item => {
          this.product_categories.push({
            value: item.id,
            text: item.product_category_name,
            label: item.product_category_name
          })
        })
      })
    },

    async getProduct (productCategories) {
      this.products = []
      await ListService.getProducts(`product_category_id=${productCategories}`).then(({ data }) => {
        data.forEach(item => {
          this.products.push({
            value: item.id,
            text: item.product_name,
            label: item.product_name
          })
        })
      })

      if (this.products.length > 0) {
        this.product.product_id = this.product_name[0].value
      }
    },

    addItem () {
      const productCategoryId = this.product.product_category_id
      const productId = this.product.product_id
      const quantity = this.product.quantity

      if (productCategoryId === '' || productId === '' || quantity === '' || quantity === 0 || quantity === '0') {
        this.$swal.fire({
          icon: 'warning',
          title: 'Validation Error',
          text: 'Oppss! There were problem with your inputs.',
          confirmButtonColor: '#FF2929',
          confirmButtonText: 'Dismiss'
        })
        return false
      }
      const isExisting = this.items?.filter(
        el => el.product_id === this.product.product_id.value
      )

      if (isExisting.length) {
        const newData = this.items.map(el => {
          if (
            el.product_id === this.product.product_id.value
          ) {
            el.quantity = Number(el.quantity) + Number(this.product.quantity)
            return el
          }

          return el
        })

        this.items = newData
        this.product.product_category_id = ''
        this.product.product_id = ''
        this.product.quantity = 0
      } else {
        this.items.push({
          product_category_name: this.product.product_category_id.label,
          product_category_id: this.product.product_category_id.value,
          product_name: this.product.product_id.label,
          product_id: this.product.product_id.value,
          quantity: this.product.quantity,
          stock_id: 0
        })

        this.product.product_category_id = ''
        this.product.product_id = ''
        this.product.quantity = 0
      }
    },

    removeSaleorderItem (item) {
      const oldItem = this.items

      const newItem = oldItem.filter(el => {
        return el.product_name !== item.product_name
      })

      this.items = newItem
    },

    async onShowProductForm (row) {
      this.stock_details.dealer_id = row.dealer_id
      this.stock_details.inventory_type = 'add'
      await StockService.getProductsListByDealer(`dealer_id=${row.dealer_id}&status=beginning`).then(({ data }) => {
        this.items = data.data
        this.$bvModal.show('modal-product-form')
      })
    },

    async onShowProductDetails (item) {
      this.getIsUpdate()
      this.$bvModal.show('modal-product-show')
      this.product.dealer_id = item.dealer_id
      this.productlist_dealer = []
      await StockService.getProductsListByDealer(`dealer_id=${item.dealer_id}&status=beginning`).then(({ data }) => {
        this.productlist_dealer = data.data
      })
    },

    async onSubmitPostForm () {
      const obj = {
        dealer_id: this.stock_details.dealer_id,
        inventory_type: this.stock_details.inventory_type,
        status: 'beginning',
        product_details: this.items
      }

      this.isBusy = true
      StockService.post(obj).then(response => {
        this.$bvModal.hide('modal-product-form')
        this.isBusy = false
        this.$swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
          confirmButtonColor: '#06C270',
          confirmButtonText: 'Dismiss'
        }).then(() => {
          this.modalShow = false
        })
      }).catch(error => {
        if (error instanceof AxiosError) {
          if (error.code === 422) {
            this.$refs.form.setErrors(error.message)
            this.$bvModal.msgBoxOk('Oppss! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger'
            })
          }
        }
        this.isBusy = false
      })
    },

    async onConfirmSubmit (bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.items.length === 0) return false

      await this.$refs.form.validate().then(async success => {
        if (success) {
          if (!this.editMode) {
            this.$swal.fire({
              title: 'Are you sure want to add this product ?',
              icon: 'question',
              showCancelButton: true,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Submit',
              cancelButtonColor: '#FF2929',
              showLoaderOnConfirm: true,
              preConfirm: () => {
                return this.onSubmitPostForm()
              },
              allowOutsideClick: () => !this.$swal.isLoading()
            })
          }
        } else {
          this.$bvModal.msgBoxOk('Oppss! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger'
          })
        }
      })
    },

    showEditProduct (item) {
      if (this.is_update === 1) {
        this.product.product_name = item.product_name
        this.product.quantity = item.quantity
        this.product.product_id = item.product_id
        this.product.stock_id = item.stock_id
        this.product.old_quantity = item.quantity
        this.$bvModal.show('modal-edit-product')
      } else {
        this.$swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: 'please request for authorization to edit',
          confirmButtonColor: '#06C270',
          confirmButtonText: 'Dismiss'
        }).then(() => {
          this.modalShow = false
        })
        this.getIsUpdate()
      }
    },

    handleEdit () {
      this.confirmPutSubmit()
    },

    async confirmPutSubmit () {
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            title: 'Do you really want to save any changes you made?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Submit',
            cancelButtonColor: '#FF2929',
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onPutSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        }
      })
    },

    async onPutSubmit () {
      const data = {
        stock_id: this.product.stock_id,
        quantity: this.product.quantity,
        product_id: this.product.product_id,
        dealer_id: this.product.dealer_id,
        old_quantity: this.product.old_quantity
      }

      this.isBusy = true
      await StockService.put(data).then(response => {
        this.$bvModal.hide('modal-product-show')
        this.isBusy = false
        this.$swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
          confirmButtonColor: '#06C270',
          confirmButtonText: 'Dismiss'
        }).then(() => {
          this.$refs.table.refresh()
        })
      }).catch(error => {
        if (error instanceof AxiosError) {
          if (error.code === 422) {
            this.$refs.form.setErrors(error.message)
          }
        }
        this.isBusy = false
      })
    }
  }
}
</script>
